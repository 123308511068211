(function($, generic, site) {
  site.HPPopup = site.HPPopup || {};
  site.HPPopup = {
    conf: {
      popover_display_modal_mask: 1,
      display_po_immediately: 1,
      homepage: 1,
      popover_filename: window.location.protocol + '//' + window.location.host + '/' + 'offers/signup_offer_cl.tmpl',
      popover_style_left: '0px',
      callback_name: 'undefined',
      pop_up_count_cookie: 'HPPopup',
      set_popover_cookie_only: 0,
      cookie_duration: '30',
      siteWidePopOverPendingOrVisible: false
    },

    initConf: function() {
      if (this.conf.popover_filename) {
        this.process();
      }
    },

    init: function() {
      try {
        this.initConf();
      } catch (err) {
        // console.log(err);
      }
    },

    process: function() {
      var self = this;
      var timeSec = 0;
      var conf = this.conf;
      var is_just_dance = $('body').hasClass('section-just-dance');
      conf.siteWidePopOverPendingOrVisible = true;

      if (Drupal.settings.globals_variables.enable_cl_session_cookie && sessionStorage.getItem('cl_newsletter_session') === '1') {
        return;
      }
      if (!generic.cookie('ELCPrivacyAccepted')) {
        timeSec = 5000;
      }
      if (is_just_dance) {
        return;
      }

      var url = conf.popover_filename;
      var includeBackgroundFlag = conf.popover_display_modal_mask;

      $.ajax(url, {
        dataType: 'html',
        success: function(html) {
          if (!/\S/.test(html)) {
            self.setCookie();
            return;
          }
          var popover_div = $('<div id="popover_container" ></div>').html(html).get(0);
          var img_height = $('img', popover_div).attr('height') || $('table', popover_div).attr('data-height');
          window.setTimeout(function() {
            generic.overlay.launch({
              content: popover_div,
              includeBackground: includeBackgroundFlag,
              fixed: 'true',
              appearDuration: 1.0,
              cssStyle: {
                overflow: 'visible',
                border: 'none',
                padding: 0,
                backgroundColor: '#000',
                height: img_height,
                width: '550px',
                position: 'fixed',
                className: '',
              }
            });

            var callback = conf.callback_name;
            if (typeof callback === 'function') {
              callback();
            }
            self.setCookie();
          }, timeSec);
        }
      });
    },

    setCookie: function() {
      var conf = this.conf;
      var expDateString = '';
      var expDate = new Date();
      expDate.setDate(expDate.getDate() + conf.cookie_duration);
      expDateString = expDate.toGMTString();
      if (Drupal.settings.globals_variables.enable_cl_session_cookie) {
        sessionStorage.setItem('cl_newsletter_session', 1);
      }
      generic.cookie(conf.pop_up_count_cookie, '1', { path: '/', expires: expDateString });
    },
  };

  site.HPPopup.init();
})(jQuery, window.generic || {}, window.site || {});
